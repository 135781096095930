// Event Orientation/Resize
window.addEventListener(
  "onorientationchange",
  function () {
    setHomeHeight(),
    setAboutTitlePos();
    },
  true
);
window.addEventListener(
  "resize",
  function () {
    setHomeHeight(),
    setAboutTitlePos();
  },
  true
);
window.addEventListener(
  "load",
  function () {
    setHomeHeight(),
    setAboutTitlePos();
  },
  false
);

// GSAP Imports
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(TextPlugin);

ScrollTrigger.defaults({
  toggleActions: "restart none none none",
});

// Tiny Slider Import
import { tns } from "tiny-slider";

const tnsCarousel = document.querySelectorAll(".ts-slide");
tnsCarousel.forEach((slider) => {
  const tnsSlider = tns({
    autoplay: true,
    autoplayHoverPause: true,
    center: true,
    container: slider,
    controls: false,
    gutter: 10,
    lazyload: true,
    mouseDrag: true,
    navPosition: "bottom",
    speed: 3000,
  });
});

const tnsSliderTestimomnials = tns({
  center: true,
  container: ".ts-slide-testimonials",
  controls: true,
  controlsText: [
    "<i class='icon-chevron-left'></i>",
    "<i class='icon-chevron-right'></i>",
  ],
  gutter: 10,
  lazyload: true,
  mouseDrag: true,
  nav: false,
  speed: 3000,
});

// Bootstrap Modal
import { Modal } from "bootstrap";

let mouseNode = document.createElement("div"),
    mouseIconNode = document.createElement("div"),
    mouseWheelNode = document.createElement("span"),
    closeButtonNode = document.createElement("button"),
    closeButtonSpan = document.createElement("span");

mouseNode.className = "mouse";
mouseIconNode.className = "mouse-icon";
mouseWheelNode.className = "mouse-wheel";
mouseNode.appendChild(mouseIconNode);
mouseIconNode.appendChild(mouseWheelNode);
closeButtonNode.className = "mobile-close btn btn-danger";
closeButtonNode.setAttribute("data-bs-dismiss", "modal");
closeButtonNode.setAttribute("aria-label", "Close");
closeButtonSpan.setAttribute("aria-hidden", "true");
closeButtonSpan.innerText = "Close";
closeButtonNode.appendChild(closeButtonSpan);

document.addEventListener("shown.bs.modal", function () {
  let parentElement = document.querySelector(".modal.show .meta").parentNode,
    childElement = document.querySelector(".modal.show .meta");

  if (window.innerWidth < 768) {
    parentElement.insertBefore(mouseNode, childElement);
    parentElement.appendChild(closeButtonNode, childElement);
    parentElement.parentNode.style.flexDirection = "column";
    new PerfectScrollbar(document.querySelector(".modal.show"));
    setMetaHeight();
  }
});

document.addEventListener("hidden.bs.modal", function () {
  if (typeof mouseNode != "undefined" && mouseNode != null) {
    mouseNode.remove();
  }
  if (typeof closeButtonNode != "undefined" && closeButtonNode != null) {
    closeButtonNode.remove();
  }
  pScrollDestroy();
});

window.addEventListener(
  "resize",
  function () {
    let modalOpen = document.querySelector(".modal.show");
    if (typeof modalOpen != "undefined" && modalOpen != null) {
      let parentElement = document.querySelector(".modal.show .meta").parentNode,
          childElement = document.querySelector(".modal.show .meta");

      if (window.innerWidth < 768) {
        parentElement.insertBefore(mouseNode, childElement);
        parentElement.appendChild(closeButtonNode, childElement);
        parentElement.parentNode.style.flexDirection = "column";
        setMetaHeight();
      } else {
        if (typeof mouseNode != "undefined" && mouseNode != null) {
          mouseNode.remove();
        }
        if (typeof closeButtonNode != "undefined" && closeButtonNode != null) {
          closeButtonNode.remove();
        }
        parentElement.parentNode.style.flexDirection = "row";
      }
    }
  },
  false
);

// Isotope
import Isotope from "isotope-layout";

document.addEventListener(
  "DOMContentLoaded",
  function () {
    setHomeHeight();
    initShow();

    // Init Isotope
    let iso = new Isotope(".grid", {
      itemSelector: ".grid-item",
      layoutMode: "fitRows",
    });

    const filters = document.getElementById("filters");
    const filterButtons = filters.childNodes;
    const grid = document.getElementById("grid");
    let allItems = grid.querySelectorAll(".grid-item");

    // Append load more button
    grid.parentNode.insertAdjacentHTML(
      "afterend",
      '<button class="btn btn-lg btn-success section-fix" id="loadMore"> Load More</button>'
    );

    loadMore(globalThis.initShow);

    // Bind filter button click
    filters.addEventListener("click", function (d) {
      let filterValue = d.target.getAttribute("data-filter");
      iso.arrange({
        filter: filterValue,
      });
      for (let i = 0; i < filterButtons.length; i++) {
        if (filterButtons[i].nodeName.toLowerCase() == "button") {
          filterButtons[i].classList.remove("btn-success", "is-checked");
          d.target.classList.add("btn-success", "is-checked");
        }
      }
      d.target.setAttribute("clicked", true);
      loadMore(globalThis.initShow);
    });

    // Load more button
    let counter = globalThis.initShow;

    function loadMore(toShow) {
      allItems.forEach((e) => {
        if (e.classList.contains("hidden")) {
          e.classList.remove("hidden");
        }
      });

      let hiddenElems = iso.filteredItems
        .slice(toShow, iso.filteredItems.length)
        .map(function (f) {
          return f.element;
        });

      hiddenElems.forEach((f) => {
        f.classList.add("hidden");
      });

      iso.arrange("layout");

      allItems.forEach((g) => {
        if (getComputedStyle(g).display === "none") {
          g.classList.add("hidden");
        }
      });

      if (hiddenElems.length == 0) {
        document.getElementById("loadMore").style.display = "none";
      } else {
        document.getElementById("loadMore").style.display = "flex";
      }
    }

    // When load more button clicked
    document.getElementById("loadMore").addEventListener("click", (h) => {
      if (filters.getAttribute("clicked")) {
        counter = globalThis.initShow;
        filters.getAttribute("clicked", false);
      } else {
        counter = counter;
      }
      counter = counter + globalThis.initShow;
      loadMore(counter);
    });

    // Form Validation
    let button = document.getElementById("submit"),
        form = document.getElementById("form"),
        inputs = document.getElementsByTagName("input"),
        inputsLen = inputs.length,
        input,
        inputMsg,
        inputValidationMsg,
        label;

    form.addEventListener(
      "invalid",
      function (e) {
        e.preventDefault();
      },
      true
    );

    while (inputsLen--) {
      input = inputs[inputsLen];
      label = input.previousElementSibling;

      if (input.hasAttribute("data-validation-msg")) {
        inputValidationMsg = input.getAttribute("data-validation-msg");
        inputMsg = document.createElement("label");
        inputMsg.innerHTML = inputValidationMsg;
        inputMsg.setAttribute("class", "error");
        input.parentNode.appendChild(inputMsg, label.nextSibling);

        input.onblur = function (e) {
          e.target.classList.add("blur");
          e.target.classList =
            !this.value || this.validity.valid === false
              ? "form-control error"
              : "form-control";
          next(e.target).style.display =
            !this.value || this.validity.valid === false ? "block" : "none";
        };
      }
    }

    // Add timeline show more/less links
    let showChar = 100,
        ellipsesText = "...",
        moreText = "Show More",
        lessText = "Show Less",
        desc = document.querySelectorAll(".desc");
    desc.forEach((e) => {
      let content = e.innerHTML;
      if (content.length > showChar) {
        let c = content.substring(0, showChar),
          h = content.substring(showChar, content.length),
          html =
            c +
            '<span class="more-ellipses">' +
            ellipsesText +
            '&nbsp;</span><span class="more-content"><span>' +
            h +
            '</span>&nbsp;&nbsp;<button class="btn btn-success more-link">' +
            moreText +
            "</button></span>";
        e.innerHTML = html;
      }
    });

    let moreLink = document.querySelectorAll(".more-link");
    moreLink.forEach((f) => {
      f.addEventListener("click", (event) => {
        if (f.classList.contains("less")) {
          f.classList.remove("less");
          f.innerHTML = moreText;
          f.parentNode.previousSibling.style.display = "inline";
          f.previousElementSibling.style.display = "none";
        } else {
          f.classList.add("less");
          f.innerHTML = lessText;
          f.parentNode.previousSibling.style.display = "none";
          f.previousElementSibling.style.display = "inline";
        }
        return false;
      });
    });

    // Copyright Notice
    var copyrightNotice = document.createElement("p");
    copyrightNotice.id = "copyrightNotice";
    copyrightNotice.appendChild(
      document.createTextNode(
        "© Copyright " +
          new Date().getFullYear() +
          " - michael.oxborrow.me - All rights reserved."
      )
    );
    var lastNode = document.getElementById("lastNode");
    lastNode.parentNode.appendChild(copyrightNotice, lastNode);
  },
  false
);

// Typing Animation Arrays
const storyWords = [
  "developing websites",
  "creating web applications",
  "designing user interfaces",
];
const historyWords = [
  "A problem solving attitude with a passion for creative challenges!",
  "Constantly developing my skills allows me to adapt to changing requirements",
];
const skillsWords = [
  '"Never stick to what you know!"',
  '"Know what you want to achieve"',
  '"Work out how to acheive it"',
];
const portfolioWords = [
  "Keeping an eye on current creative &amp; technical trends",
  "Optimising and branding of client website/web application",
  "Undertaken to meet specific business/client/stakeholder requirements",
];
const processWords = [
  "As a designer I never feel that perfection is achieved until there is nothing left to take away",
  "From idea to launch I do the entire process of building a complete website/web application",
];
const testimonialsWords = [
  "I work with great people",
  "Close partnerships with really cool clients lets me do amazing, creative work",
];
const coursesWords = [
  "I've completed a lot of training over the years",
  "Here are some of the courses I've completed",
];
const contactWords = [
  "Are you looking for a freelancer?",
  "Maybe you're considering hiring me for a permanent role?",
];

// Typing Animation Timelines
let cursor = gsap.to(".cursor", {
    opacity: 0,
    ease: "power2.inOut",
    repeat: -1,
  }),
    storyTl = gsap.timeline({ repeat: -1 }),
    historyTl = gsap.timeline({ repeat: -1 }),
    skillsTl = gsap.timeline({ repeat: -1 }),
    portfolioTl = gsap.timeline({ repeat: -1 }),
    processTl = gsap.timeline({ repeat: -1 }),
    testimonialsTl = gsap.timeline({ repeat: -1 }),
    coursesTl = gsap.timeline({ repeat: -1 }),
    contactTl = gsap.timeline({ repeat: -1 });

storyWords.forEach((storyWord) => {
  let tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });
  tl.to("#storyTitle", { duration: 2, text: storyWord });
  storyTl.add(tl);
});

historyWords.forEach((historyWord) => {
  let tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });
  tl.to("#historyTitle", { duration: 2, text: historyWord });
  historyTl.add(tl);
});

skillsWords.forEach((skillsWord) => {
  let tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });
  tl.to("#skillsTitle", { duration: 2, text: skillsWord });
  skillsTl.add(tl);
});

portfolioWords.forEach((portfolioWord) => {
  let tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });
  tl.to("#portfolioTitle", { duration: 2, text: portfolioWord });
  portfolioTl.add(tl);
});

processWords.forEach((processWord) => {
  let tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });
  tl.to("#processTitle", { duration: 2, text: processWord });
  processTl.add(tl);
});

testimonialsWords.forEach((testimonialsWord) => {
  let tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });
  tl.to("#testimonialsTitle", { duration: 2, text: testimonialsWord });
  testimonialsTl.add(tl);
});

coursesWords.forEach((coursesWord) => {
  let tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });
  tl.to("#coursesTitle", { duration: 2, text: coursesWord });
  coursesTl.add(tl);
});

contactWords.forEach((contactWord) => {
  let tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });
  tl.to("#contactTitle", { duration: 2, text: contactWord });
  contactTl.add(tl);
});

// About
const uiDesignBoxes = [
  "#Monitor .cls-blue",
  "#Monitor .cls-purple",
  "#Monitor .cls-pink",
  "#Monitor .cls-orange",
  "#Monitor .cls-green",
  "#Monitor .cls-yellow"
];
const uiDesignBoxesPortable = [
  "#Tablet .cls-blue",
  "#Tablet .cls-purple",
  "#Tablet .cls-pink",
  "#Tablet .cls-orange",
  "#Tablet .cls-green",
  "#Tablet .cls-yellow",
  "#Mobile .cls-blue",
  "#Mobile .cls-purple",
  "#Mobile .cls-pink",
  "#Mobile .cls-orange",
  "#Mobile .cls-green",
  "#Mobile .cls-yellow"
];
uiDesignBoxes.forEach((uiDesignBox) => {
  gsap.from(uiDesignBox, {
    duration: 2,
    ease: "power3.inOut",
    scrollTrigger: {
      trigger: "#uiDesign",
      toggleActions: "restart none restart none",
    },
    stagger: {
      from: "random",
      amount: 1
    },
    y: -340
  })
});
uiDesignBoxesPortable.forEach((uiDesignBoxPortable) => {
  gsap.from(uiDesignBoxPortable, {
    duration: 2,
    ease: "power3.inOut",
    scrollTrigger: {
      trigger: "#uiDesign",
      toggleActions: "restart none restart none",
    },
    stagger: {
      from: "random",
      amount: 2
    },
    y: 200
  })
});
gsap.from("#Lamp", {
  delay: 2,
  duration: 1,
  ease: "power1.inOut",
  opacity: 0,
  scrollTrigger: {
    trigger: "#uiDesign",
    toggleActions: "restart none restart none",
  },
  x: 100
});
gsap.from("#Ruler", {
  delay: 1.5,
  duration: 2,
  ease: "power3.inOut",
  scrollTrigger: {
    trigger: "#uiDesign",
    toggleActions: "restart none restart none",
  },
  transformOrigin: "center center",
  x: -1000
});
gsap.from("#Compass", {
  delay: 1.6,
  duration: 2,
  ease: "power3.inOut",
  scrollTrigger: {
    trigger: "#uiDesign",
    toggleActions: "restart none restart none",
  },
  transformOrigin: "center center",
  x: -1000
});
gsap.from("#SetSquare", {
  delay: 1.7,
  duration: 2,
  ease: "power3.inOut",
  scrollTrigger: {
    trigger: "#uiDesign",
    toggleActions: "restart none restart none",
  },
  transformOrigin: "center center",
  x: -1000
});
gsap.from("#CoffeeMug", {
  delay: 1.8,
  duration: 2,
  ease: "power3.inOut",
  scrollTrigger: {
    trigger: "#uiDesign",
    toggleActions: "restart none restart none",
  },
  transformOrigin: "center center",
  x: -1000
});
gsap.from("#Light", {
  delay: 3.6,
  duration: 0.5,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#uiDesign",
    toggleActions: "restart none restart none",
  },
  opacity: 0
});

//Avatar
gsap.to("#Avatar", {
  duration: 3,
  ease: "power1.inOut",
  scrollTrigger: {
    end: "100% top",
    scrub: true,
    start: "50% center",
    toggleActions: "restart pause reverse pause",
    trigger: "#Home"
  },
  opacity: 0,
  scale: 1.6,
  x: "160%"
});

//Intro Title
gsap.to("#siteTitle H1", {
  duration: 3,
  ease: "power1.inOut",
  scrollTrigger: {
    end: "100% top",
    scrub: true,
    start: "50% center",
    toggleActions: "restart pause reverse pause",
    trigger: "#Home"
  },
  opacity: 0,
  x: "-200%"
});

//Intro Subtitle
gsap.to("#siteTitle H2", {
  duration: 3,
  ease: "power1.inOut",
  scrollTrigger: {
    end: "100% top",
    scrub: true,
    start: "55% center",
    toggleActions: "restart pause reverse pause",
    trigger: "#Home"
  },
  opacity: 0,
  x: "-200%"
});

//Intro Paragraph
gsap.to(".intro p", {
  duration: 3,
  ease: "power1.inOut",
  scrollTrigger: {
    end: "100% top",
    scrub: true,
    start: "60% center",
    toggleActions: "restart pause reverse pause",
    trigger: "#Home"
  },
  opacity: 0,
  x: "-200%"
});

//Intro Buttons
gsap.to(".intro .btn", {
  duration: 2,
  ease: "power1.inOut",
  scrollTrigger: {
    end: "100% top",
    scrub: true,
    start: "65% center",
    toggleActions: "restart pause reverse pause",
    trigger: "#Home"
  },
  opacity: 0,
  y: "-400%"
});


// Story
const storyNumbers = [
  ".story-number--1",
  ".story-number--2",
  ".story-number--3",
  ".story-number--4",
];

storyNumbers.forEach((storyNumber) => {
  let sn = document.querySelector(storyNumber).getAttribute("data-number");
  gsap.to(storyNumber, {
    duration: 2,
    ease: "power1.in",
    snap: { textContent: 1 },
    scrollTrigger: {
      trigger: storyNumber,
      toggleActions: "restart none restart none",
    },
    textContent: sn,
  });
});

// History
ScrollTrigger.batch(".note", {
  onEnter: (elements) => {
    gsap.from(elements, {
      autoAlpha: 0,
      y: 500,
      stagger: 0.25,
    });
  },
  once: true,
});

// Skills
const skillsNumbers = [
  ".skills-number--1",
  ".skills-number--2",
  ".skills-number--3",
  ".skills-number--4",
  ".skills-number--5",
  ".skills-number--6",
];

skillsNumbers.forEach((skillsNumber) => {
  let skn = document.querySelector(skillsNumber).getAttribute("data-percent");
  gsap.to(skillsNumber, {
    duration: 2,
    ease: "power1.in",
    snap: { textContent: 1 },
    scrollTrigger: {
      trigger: skillsNumber,
      toggleActions: "restart none restart none",
    },
    textContent: skn,
  });
});

const skillsCharts = [
  ".skills-chart1",
  ".skills-chart2",
  ".skills-chart3",
  ".skills-chart4",
  ".skills-chart5",
  ".skills-chart6",
];

skillsCharts.forEach((skillsChart) => {
  gsap.from(skillsChart, {
    duration: 2,
    strokeDasharray: 0,
    ease: "bounce.out",
    scrollTrigger: {
      trigger: skillsChart,
      toggleActions: "restart none restart none",
    },
  });
});

// Import Perfect Scrollbar
import PerfectScrollbar from "perfect-scrollbar";

function next(elem) {
  do {
    elem = elem.nextSibling;
  } while (elem && elem.nodeType !== 1);
  return elem;
}

const nameField = document.getElementById("inputName");
const emailField = document.getElementById("inputEmail");
const submitButton = document.getElementById("submit");

document.addEventListener("keyup", (event) => {
  if (event.target !== nameField && event.target !== emailField) {
    return;
  } else {
    let isValidName = nameField.checkValidity();
    let isValidEmail = emailField.checkValidity();

    if (isValidName && isValidEmail) {
      submitButton.disabled = false;
    } else {
      submitButton.disabled = true;
    }
  }
});

let pScroll1 = document.getElementsByClassName("ps--active-y");
let pScroll2 = document.getElementsByClassName("ps");

// Functions
function setMetaHeight() {
  let modalWidth = document.querySelector(
    ".modal.show .modal-body"
  ).offsetWidth;
  let colWidth = modalWidth / 2;
  let metaHeight = colWidth / 1.333333;
  if (document.querySelector(".modal.show").querySelectorAll(".tns-lazy-img").length > 1) {
    metaHeight += 52;
  } else {
    if (pScroll1.length > 0) {
      pScroll1[0].style.height = metaHeight;
    }
  }
}

function pScrollDestroy() {
  if (pScroll1.length > 0) {
    pScroll1[0].classList.remove("ps--active-y");
  }
  if (pScroll2.length > 0) {
    pScroll2[0].classList.remove("ps");
  }
}

function setHomeHeight() {
  let documentHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  
  let siteTitleHeight = document.getElementById("siteTitle").clientHeight;

  document.querySelector("#Home").style.height = documentHeight + "px";
  document.querySelector(".avatar").style.maxHeight =
    documentHeight - 144;

  if (window.innerWidth > window.innerHeight) {
    document.querySelector(".info").classList.add("landscape");
  } else {
    document.querySelector(".info").classList.remove("landscape");
  }
}

function setAboutTitlePos() {
  let aboutContainer = document.getElementById("aboutContainer");
  let aboutTitle = document.getElementById("aboutTitle");
  let aboutIntro = document.getElementById("aboutIntro");
  if (window.innerWidth < 992) {
    aboutContainer.prepend(aboutTitle);
  } else {
    aboutIntro.prepend(aboutTitle);
  }
}

let initShow = function () {
  if (window.innerWidth > 575 && window.innerWidth < 768) {
    globalThis.initShow = 6;
  } else if (window.innerWidth > 767 && window.innerWidth < 992) {
    globalThis.initShow = 9;
  } else if (window.innerWidth > 991 && window.innerWidth < 1200) {
    globalThis.initShow = 12;
  } else if (window.innerWidth > 1199 && window.innerWidth < 1400) {
    globalThis.initShow = 15;
  } else if (window.innerWidth > 1399 && window.innerWidth < 1900) {
    globalThis.initShow = 18;
  } else if (window.innerWidth > 1900) {
    globalThis.initShow = 21;
  } else {
    globalThis.initShow = 3;
  }
};
